import { Modal } from "antd";
import SizeBox from "@/components/SizeBox";
import { CommonPrimaryBtn } from "@/components/CommonBtn";
import host from "@/utils/host";


const AuthorizationModal = ({ show, onCancel }) => {
  return <Modal title={<div className={"text_222 font-bold text-[17px]"}>Authorization Required</div>} centered footer={null} width={656} open={show} onCancel={(e) => {
    e.stopPropagation();
    onCancel();
  }}>
    <div style={{ marginTop: "50px", lineHeight: "21px", color: "#222" }} className={"px-[24px] text-[14px]"}>
      <ul style={{ listStyleType: "disc" }}>
        <li style={{ color: "#FF6A00", fontWeight: "bold" }}>
          Attention! Please make sure that the TikTok account you logged in on your webpage or App is the same as the one you logged in at anyStarr, otherwise the authorization will fail!
        </li>
        <li>
          After authorization, you can use more functions, including but not limited to:
        </li>
        <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
          <li>
            View your <span style={{ color: "#FF6A00", fontWeight: "bold" }}>TikTok marketplace performance</span>
          </li>
          <li>
            View your <span style={{ color: "#FF6A00", fontWeight: "bold" }}>showcase information</span>
          </li>
          <li>
            <span style={{ color: "#FF6A00", fontWeight: "bold" }}>Increase commission</span> on your showcase products
            (under development)
          </li>
          <li>
            Check the progress of your <span style={{ color: "#FF6A00", fontWeight: "bold" }}>sample application</span> (under development)
          </li>
        </ul>
        <li>
          Please <span style={{ color: "#FF6A00", fontWeight: "bold" }}>select all</span> authorized content so that we can help you improve your TikTok e-commerce performance.
        </li>
        <li>
          The data will not be used for any other purposes.
        </li>
        <li>
          You can cancel the authorization in the personal center.
        </li>
      </ul>

      <a href={`${host}/api/v2/tiktok/login`} target={"_blank"}>
        <CommonPrimaryBtn className={"rounded-[24px] h-[40px] mt-[39px]"}>Authorization</CommonPrimaryBtn>
      </a>
    </div>
  </Modal>;
};

export default AuthorizationModal;
