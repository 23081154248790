import {Modal} from "antd";
import SizeBox from "@/components/SizeBox";
import {useEffect, useState} from "react";
import {FailIcon} from "@/config/config";
import eventSub, {EventName} from "@/common/EventSub";


const InterfaceErrorModal = ( ) =>{
  const [show, setShow] = useState(false);

  // const onEventCallBack = () =>{
  //   setShow(true);
  // };

  // const initNC = () =>{
  //   eventSub.subscribe(EventName.InterfaceError, onEventCallBack);
  // };
  //
  // useEffect(()=>{
  //   initNC();
  //   return ()=>{
  //     eventSub.unsubscribe(EventName.InterfaceError, onEventCallBack);
  //   };
  // }, []);

  return (
    <Modal title={""} centered footer={null} width={330} open={show} onCancel={(e) => {
      e.stopPropagation();
      setShow(false);
    }}>
      <div style={{ padding: "0px" }}>
        <div className={"w-full justify-center flex mb-[14px] mt-[5px]"}>
          <img src={FailIcon} className={"w-[50px] h-[50px]"} />
        </div>
        <div style={{ textAlign: "center" }}>
          Interface exception, please contact the administrator or refresh the page.
        </div>
        <SizeBox h={15}></SizeBox>
      </div>
    </Modal>
  );
};


export default InterfaceErrorModal;
