import { isMobile } from "@/utils/action";

class AnalyticsTracker {
  trackPageView(page) {
    throw new Error("trackPageView method should be implemented");
  }

  trackEvent(category, action, label, value) {
    throw new Error("trackEvent method should be implemented");
  }
}


class GATracker extends AnalyticsTracker {
  trackPageView(page) {
    if (window.gtag) {
      window.gtag('config', 'G-RGC7VTRCE4', {
        page_path: page,
      });
    }
  }

  trackEvent(action) {
    if (typeof window !== 'undefined' && window.gtag) {
      const lastTag = isMobile() ? "h5" : "web";
      const realActionName = `${action}_v2_${lastTag}`;
      window.gtag('event', realActionName, {
        event_category: realActionName,
        event_label: realActionName,
        value: realActionName,
      });
      const allAction = `${action}_v2_all`;
      window.gtag('event', allAction, {
        event_category: allAction,
        event_label: allAction,
        value: allAction,
      });
    }
  }
}

const gaTracker = new GATracker();
export default gaTracker;
