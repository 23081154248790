import React, { createContext, useState, useContext } from 'react';

// 创建 Context
const FeedbackContext = createContext();

// 提供 Context 的 Provider 组件
export const FeedbackProvider = ({ children }) => {
  const [feedbackShow, setFeedbackShow] = useState(false);

  return (
    <FeedbackContext.Provider value={{ feedbackShow, setFeedbackShow }}>
      {children}
    </FeedbackContext.Provider>
  );
};

// 自定义 Hook，便于消费 Context
export const useFeedback = () => useContext(FeedbackContext);
