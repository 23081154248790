import {useSelector} from "react-redux";
import {useEffect, useState} from "react";


const useLogin = () =>{

  const userInfo = useSelector(state => state.home.userInfo.userInfo);

  const [loginModalShow, setLoginModalShow] = useState(false);

  useEffect(()=>{
    const token = localStorage.getItem("token");
    if (!token){
      setLoginModalShow(true);
    }
  }, []);

  return {
    needLogin:!userInfo?.displayName,
    loginModalShow
  };

};

export default useLogin;
