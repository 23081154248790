import {Input, message, Modal} from "antd";
import {useEffect, useState} from "react";
import SizeBox from "@/components/SizeBox";
import {APIFeedback} from "@/api";
import CommonLoading from "@/components/CommonLoading";
import {useSelector} from "react-redux";
import {Icon} from "@iconify/react";
import styles from "@/components-mobile/FeedbackPopup/index.module.scss";


const QuestionModal = ({ show, onCancel }) =>{
  const [checked, setChecked] = useState();
  const [selectedQue, setSelectedQue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errMsg, setErrMsg] = useState(false);


  const question = [
    {
      id:1,
      des:"Products Selection"
    },
    {
      id:2,
      des:"Commission Increase"
    },
    {
      id:3,
      des:"Sample Application"
    },
    {
      id:4,
      des:"Earnings Analysis"
    },
    {
      id:5,
      des:"Login & Authorization"
    },
    {
      id:6,
      des:"Others"
    },
  ];


  const addQ = (id) =>{
    if (selectedQue.includes(id)){
      setSelectedQue([...selectedQue.filter(item => item !== id)]);
      return;
    }
    selectedQue.push(id);
    setSelectedQue([...selectedQue]);
  };

  const creatFeedBack = async () =>{
    try {

      if ( checked === undefined ){
        setErrMsg(true);
        return;
      }

      if ( checked <= 5 && selectedQue.length <= 0){
        setErrMsg(true);
        return;
      }

      setLoading(true);
      const res = await APIFeedback(JSON.stringify({
        score:checked,
        dissatisfied: checked > 5 ? "" : question.filter(item=>selectedQue.includes(item.id)).map(item => item.des).join(","),
        suggestion:inputValue,
      }));
      setLoading(false);
      message.success("Successfully submitted your feedback, thanks!");
      onCancel();
    } catch (e){
      message.info(e.message ?? "Server Error");
      onCancel();
      setLoading(false);
    }
  };

  const content = (
    <div onClick={(e)=>{
      e.stopPropagation();
      e.preventDefault();
    }} className={`fixed ${ show ? "popup-modal-enter" : "popup-modal-exit" } shadow-2xl bottom-[40px] right-[80px] bg-white max-w-[520px] p-[20px] rounded-[20px]`}>

      <div className={"flex items-center justify-between mb-5"}>
        <div>
          anyStarr Feedback
        </div>
        <div onClick={onCancel} className={"cursor-pointer"}>
          <Icon icon='line-md:close' className={"text-[#A7A7A7FF] text-[20px]"} />
        </div>
      </div>

      <div className={"max-h-[500px] overflow-scroll"}>
        <div className={"mt-6"}>
          <div className={"text-[14px] font-bold text_222"}>
            <span className={"text_red"}>*</span>
            How satisfied are you with the anyStarr Platform?
          </div>
          <div className={"mt-3 flex items-center gap-2 justify-between"}>
            {
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item=>{
                return <div onClick={()=>setChecked(item)} className={`bg-[#EDEDED] duration-75 ease-in transaction transaction-all ${ checked === item ? "bg-[#FF6E1F] text-white font-bold" : "bg-[#EDEDED] text_222"} cursor-pointer rounded w-7 h-6 flex items-center justify-center`}>{item}</div>;
              })
            }
          </div>

          <div className={"flex items-center justify-between mt-3"}>
            <div className={"text_a7 text-sm"}>Not Satisfied</div>
            <div className={"text_a7 text-sm"}>Most Satisfied</div>
          </div>
        </div>
        <div className={`mt-6 ${ checked && checked <= 5 ? "animated animate__fadeInUp" : "hidden" }`}>
          <div className={"text-[14px] font-bold text_222"}>
            <span className={"text_red"}>*</span>
            What are you most dissatisfied with?
          </div>

          <div className={"flex flex-col gap-3 mt-3"}>
            {
              question.map(item=>{
                return  <div onClick={()=>addQ(item.id)} className={"flex items-center cursor-pointer"}>
                  <div className={`custom_check_icon ${ selectedQue.includes(item.id) ? "custom_checked_icon" : "" }`}></div>
                  <div className={"text_222 ml-3 text-sm"}>
                    {item.des}
                  </div>
                </div>;
              })
            }

          </div>
        </div>

        <div className={"mt-6"}>
          <div className={"text-[14px] font-bold text_222"}>
            What else can we do better?
          </div>

          <div className={"mt-3 bg-[#EDEDED]"}>
            <Input.TextArea
              onChange={(e)=>setInputValue(e.target.value)}
              rootClassName={"!bg-[#EDEDED]"}
              count={{
                show: true,
                max: 500,
              }}
            >

            </Input.TextArea>
          </div>
        </div>

        <SizeBox h={50}></SizeBox>

      </div>
      {
        errMsg && <div className={"text-red-500"}>Please complete the questions marked with *</div>
      }
      <div className={"flex items-center justify-center mt-3"}>
        <div onClick={creatFeedBack} className={"bg-[#FF6E1F] flex items-center rounded cursor-pointer hover:opacity-50 h-[28px] px-7 text-white font-bold text-sm"}>
          {
            loading && <CommonLoading className={"w-5 h-5"}></CommonLoading>
          }
          Submit
        </div>
      </div>
    </div>
  );

  // return <Modal title={"anyStarr Feedback"}
  //               centered footer={null} width={360} open={show} onCancel={() => {
  //   onCancel();
  // }}>
  //
  // </Modal>;

  return show ? content : null;
};


export default QuestionModal;
